import * as React from "react"
import tw from "twin.macro";

import { H1 } from "../components/Heading";
import { Layout } from "../components/Layout";
import { LinkGatsbyComponent } from "../components/Link";

const Center = tw.div`
  text-center
`;

const NotFoundPage = () => {
  return (
    <Layout>
      <Center>
        <H1>Tady nic není</H1>
        <LinkGatsbyComponent to="/" backIcon title="Zpět na hlavní stránku" />
      </Center>
    </Layout>
  )
};

export default NotFoundPage
